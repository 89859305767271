import DeleteDisk from '@/layouts/Stack/components/DeleteDisk';
import showErrorModal from '@/mixins/showErrorModal';
import storeMixin from '@/layouts/Stack/mixins/index';
import Vue from 'vue';
import BaseLoader from '@/components/BaseLoader/BaseLoader';
import modals from '@/mixins/modals';

export default {
  mixins: [showErrorModal, storeMixin, modals],
  props: {
    tariff: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      data: {},
      isProcessing: false,
    };
  },
  methods: {
    deleteDisk: function (instance) {
      const that = this;
      let res = '';
      let self;
      let time;
      return new Promise(() => {
        const selfName = 'deleteDisk';
        this.$modals.open({
          name: selfName,
          size: 'medium',
          component: DeleteDisk,
          closeOnBackdrop: false,
          props: {
            name: instance.name,
            instance: instance,
          },
          on: {
            change: data => {
              instance = data;
            },
            notready: () => {
              Vue.set(this.modal.footer.confirm.props, 'disabled', true);
            },
          },
          onOpen(inst) {
            self = inst;
          },
          onClose: () => (this.modal = null),
          onDismiss: () => (this.modal = null),
          footer: {
            confirm: {
              props: { title: this.$t('sure.confirm'), color: 'error' },
              on: {
                click: () => {
                  self.text = null;
                  self.component = BaseLoader;
                  self.closable = false;
                  self.footer = false;
                  time = Date.now();
                  this.isProcessing = true;
                  if (this.snapshotsId.length > 0) {
                    this.snapshotsId.map(
                      async (snap, i) =>
                        await this.deleteSnapshot(snap)
                          .then(async () => {
                            const payload = { disk: this.id };
                            await this.$store
                              .dispatch('moduleStack/checkDiskStatus', payload)
                              .then(async data => {
                                const res = await data.status;
                                // console.log(res);
                                if (res !== 'available') {
                                  return this.updateDiskStatusD(_, {
                                    disk: this.id,
                                    type: 'volumes',
                                    silent: true,
                                  });
                                } else return res;
                              })
                              .catch(e => this.showError(e));

                            // res = 'success';
                          })
                          .then(async () => {
                            // console.log(i);
                            if (i === this.snapshotsId.length - 1) {
                              setTimeout(async () => {
                                await this.deleteCurrentDisk(this.id).then(async data => {
                                  // console.log(data);
                                });
                              }, 3000);
                              res = 'success';
                              return res;
                            }
                          })
                    );
                  } else {
                    this.deleteCurrentDisk(instance.id).then(data => {
                      if (data === 202) {
                        res = 'delete';
                      } else res = 'fail';
                    });
                  }
                  // .catch(() => (res = 'fail'))
                  // .finally(() => {
                  const delay = Date.now() - time < 1000 ? 1000 : 0;
                  setTimeout(() => {
                    self.component = null;
                    if (res === 'delete') self.html = true;
                    self.closable = true;
                    self.text = this.$t(`modal.sure.${res}`, { name: instance.name });
                    self.footer = {
                      centered: true,
                      confirm: {
                        props: { title: this.$t('modal.sure.confirm') },
                        on: {
                          click: () => {
                            this.$modals.close();

                            this.fetchServer();
                            // this.showResModal('Диск успешно удалён.');
                            this.$router
                              .push({
                                name: 'containerDisk',
                              })
                              .catch(err => {
                                if (
                                  err.name !== 'NavigationDuplicated' &&
                                  !err.message.includes(
                                    'Avoided redundant navigation to current location'
                                  )
                                ) {
                                  this.showErrorModal(err);
                                }
                              });
                          },
                        },
                      },
                    };
                  }, delay);
                  // });
                },
              },
            },
            cancel: {
              on: {
                click: () => {
                  that.$modals.close();
                },
              },
            },
          },
        });
      });
    },
    sendDiskAction(params) {
      return this.$store.dispatch('moduleStack/setDiskAction', {
        id: this.tariff.id,
        params,
      });
    },
    async updateDiskStatusD(callback, payload) {
      this.timerCnt = 1;
      this.timerId = setTimeout(() => {
        this.$store
          .dispatch('moduleStack/checkDiskStatus', {
            disk: this.id,
            type: 'volumes',
            silent: true,
          })
          .then(async data => {
            // console.log(data.status);
            if (!['available'].includes(data.status)) {
              this.res = data.status;
              await this.updateDiskStatusD(callback, payload);
            } else {
              this.timerCnt = 1;
              this.isProcessing = false;
              clearTimeout(this.timerId);
              this.res = data.status;
              return 'success';
            }
          });
      }, 1000 * this.timerCnt);
    },
    deleteSnapshot(snap) {
      return this.$store.dispatch('moduleStack/deleteSnapshot', {
        snap: snap,
      });
    },
    deleteCurrentDisk(id) {
      // console.log(id);
      return this.$store.dispatch('moduleStack/deleteDisk', {
        disk: id,
      });
    },
  },
};
