var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"stack-context-menu"},[(_vm.menu?.length)?_vm._l((_vm.menu),function(item){return _c('div',{key:item.key,staticClass:"stack-context-menu__group"},[_c(item.attrs ? 'a' : 'div',_vm._b({key:item.key,tag:"component",staticClass:"stack-context-menu__item standart-title",class:{ [`stack-context-menu__item--${item.color}`]: !!item.color },attrs:{"disabled":item.disabled},on:{"click":function($event){return _vm.onClick(item)}}},'component',item.attrs,false),[(
            (_vm.quota < 1 && item.key === 'resize') ||
            (item.key === 'snapshot' && _vm.quota < _vm.tariff.size) ||
            (item.key === 'snapshot' && _vm.quotaSnap === 0)
          )?_c('plain-button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
            content: item.key === 'resize' ? _vm.textResize : _vm.textSnapshot,
            placement: 'auto',
            container: false,
            autoHide: false,
            trigger: 'click hover',
          }),expression:"{\n            content: item.key === 'resize' ? textResize : textSnapshot,\n            placement: 'auto',\n            container: false,\n            autoHide: false,\n            trigger: 'click hover',\n          }"}],staticClass:"stack-context-menu__item-btn",attrs:{"icon":"error","size":"medium","color":"del","tabindex":"-1"}}):_vm._e(),_vm._v(" "+_vm._s(_vm.$t(`actions.${item.key}`))+" ")],1)],1)}):[_c('div',{staticClass:"stack-context-menu__noact standart-text"},[(_vm.isSuspended)?_c('div',{staticClass:"standart-title"},[_vm._v(" "+_vm._s(_vm.$t('noact.suspended'))+" ")]):_vm._e(),_c('div',[_vm._v(_vm._s(_vm.$t('noact.noactions')))])])]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }