/* eslint-disable vue/no-v-html */
<template>
  <div class="resize">
    <div class="l-col">
      <label class="standart-text">
        <label class="standart-text">
          <p v-html="$t('disk.text', { name: name, id: instance.id })"></p>
          <!--          {{ $t('disk.text', { name: name, id: instance.id }) }}-->
        </label>

        <!--        <p v-html="summaryCost"></p>-->
      </label>
      <!--      <label class="typo__label standart-text">{{ $t('disk.label') }}</label>-->
      <!--      <label class="typo__label standart-text"-->
      <!--        ><strong>{{ // ' ' + name }}</strong></label-->
      <!--      >-->
    </div>
    <div v-if="snapshots && snapshots.length > 0" class="l-col">
      <p>
        <label class="typo__label small-title underline">{{ $t('disk.encore') }}</label>
      </p>
      <div v-if="snapshots.length">
        <div v-for="snap in snapshots" :key="snap.id">
          <div class="l-col">
            <label class="typo__label medium-title">{{
              `${snap.name} от ${dateSnapshots(snap)}`
            }}</label>
          </div>
        </div>
      </div>
    </div>
    <div class="l-col medium-text">
      <label class="standart-text">
        <p v-html="summaryCost"></p>
      </label>
    </div>
  </div>
</template>

<script>
import setFocus from '@/mixins/setFocus';
import { dev } from '@/layouts/LoginPage/mixins/currentProvider';
import { format, parseISO } from 'date-fns';
export default {
  name: 'DeleteDisk',
  components: {},
  mixins: [setFocus],
  props: {
    name: {
      type: String,
      default: '',
    },
    instance: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      shared: [],
      private: [],
      float: [],
      fixed: [],
      sharedIp: [],
    };
  },
  computed: {
    summaryCost() {
      const cost =
        this.snapshots.length === 0
          ? this.cost(`volume_${this.instance.volume_type}`)
          : this.cost(`volume_${this.instance.volume_type}`) + this.snapExpenses;
      return this.$t('cost', {
        cost: this.$n(cost, 'currency', this.$i18n.locale),
      });
    },
    snapshots() {
      return this.$store.state.moduleStack.snapshots.filter(x => x.volume_id === this.instance.id);
      // .map(i => i.id) || []
    },
    snapExpenses() {
      let sum = 0;
      this.snapshots.forEach(x => {
        // sum += x.size * this.cost(`snapshot_${this.instance.volume_type}`);
        sum += this.cost(`snapshot_${this.instance.volume_type}`);
        // console.log(sum, x.size, this.cost(`snapshot_${this.instance.volume_type}`));
      });
      return sum;
    },
  },
  mounted() {
    // this.generatePass();
    this.init();
  },
  methods: {
    price(name) {
      return this.$store.state.moduleStack.price.length > 0
        ? this.$store.state.moduleStack.price.find(x => x.intname === name)[dev]
        : null;
    },
    dateSnapshots(snap) {
      return format(snap.created_at, 'dd.MM.yyyy HH:mm:ss');
    },
    cost(name) {
      return this.price(name) ? this.price(name) * this.instance.size : 0;
    },
    init() {},
  },
};
</script>

<i18n>
{
  "ru": {

    "cost": "Расходы уменьшатся на <b>%{cost}</b> в час.",
    "disk": {
      "label": "Удалить диск:",
      "text": "Удалить диск: <b>%{name}</b> (id: %{id})?",
      "encore": "Вместе с диском удалится:",
      "undelete": "Останутся без изменения:",
      "description" : "Текстовое описание"
    }
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.resize {
  text-overflow: unset;
  overflow: visible;

  &-row{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 1.5rem 1.5rem;
&-input {
  padding: 1.5rem 1.5rem;
}
  }
}
  .underline {
    text-decoration underline red;
    //color: $error
  }
</style>
