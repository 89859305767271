import DeattachDisk from '@/layouts/Stack/components/DeattachDisk';
import showErrorModal from '@/mixins/showErrorModal';
import Vue from 'vue';
import modals from '@/mixins/modals';

export default {
  mixins: [showErrorModal, modals],
  computed: {
    // quota() {
    //   return this.$store.getters['moduleStack/aviableSpaseVolume'];
    // },
    projectId() {
      return this.$store.getters['moduleStack/projectId'];
    },
    id() {
      return this.tariff.id;
    },
  },
  methods: {
    deattachDisk: function (instance, disk) {
      // console.log(instance, disk);
      const that = this;
      return new Promise(() => {
        const selfName = 'DeattachDisk';
        this.$modals.open({
          name: selfName,
          size: 'medium',
          component: DeattachDisk,
          closeOnBackdrop: false,
          props: {
            instance: instance,
            // size: size + 1,
          },
          // text: this.$t('sure.text'),
          on: {
            instance: data => {
              instance = data;
            },
          },
          onOpen: inst => (this.modal = inst),
          onClose: () => (this.modal = null),
          onDismiss: () => (this.modal = null),
          footer: {
            // centered: true,
            confirm: {
              props: { title: this.$t('sure.confirm') },
              on: {
                click: () => {
                  Vue.set(this.modal.footer.confirm.props, 'loading', true);
                  // console.log(this.instance);
                  this.deattachDiskToInstance(instance, disk)
                    .then(data => {
                      console.log(data);
                      if (data.status === 202) {
                        this.$modals.close({ name: selfName });
                        const payload = { project: this.projectId, disk: this.id };
                        this.$store
                          .dispatch('moduleStack/checkDiskStatus', payload)
                          .then(async data => {
                            const res = await data.status;
                            console.log(res);
                            if (res !== 'aviable') {
                              return this.updateDiskStatus(_, {
                                project: this.projectId,
                                disk: this.id,
                                type: 'volumes',
                                silent: true,
                              });
                            }
                          })
                          .catch(e => this.showError(e));
                        // });
                        // setTimeout(() => this.openModalsCode(code, phone, route), 200);
                        this.showResModal('Диск успешно отключен.');
                      }
                    })
                    .catch(e => this.showError(e));
                },
              },
            },
            cancel: {
              on: {
                click: () => {
                  that.$modals.close();
                },
              },
            },
          },
        });
      });
    },
    async updateDiskStatus(callback, payload) {
      // console.log(payload);
      this.timerCnt = 1;
      this.timerId = setTimeout(() => {
        this.$store
          .dispatch('moduleStack/checkDiskStatus', {
            project: this.projectId,
            disk: this.id,
            type: 'volumes',
            silent: true,
          })
          .then(async data => {
            // console.log(data.status);
            if (!['available'].includes(data.status)) {
              this.res = data;
              await this.updateDiskStatus(callback, payload);
            } else {
              console.log('data', data.status);
              this.timerCnt = 1;
              this.isProcessing = false;
              clearTimeout(this.timerId);
              this.res = data.status;
              return 'success';
            }
          });
      }, 1000 * this.timerCnt);
    },
    deattachDiskToInstance(instance, disk) {
      const params = {
        instance: instance,
        disk: disk,
      };
      return this.$store.dispatch('moduleStack/deattachDisk', params).catch(e => {
        this.showError(e);
      });
    },
  },
};
